import axios from 'axios';
import { throttleAdapterEnhancer } from 'axios-extensions';
import { refreshToken } from '~/shared/api/auth.api';

const throttleConfig = {
  threshold: 1000,
};

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_API}/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    apiKey: `${process.env.VUE_APP_APIKEY}`,
  },
  adapter: throttleAdapterEnhancer(axios.defaults.adapter, throttleConfig),
});

const requestInterceptor = async config => {
  config.headers['Authorization'] = await refreshToken();
  return config;
};

const setAuthToken = token => {
  apiClient.defaults.headers['Authorization'] = `Bearer ${token}`;
};

apiClient.interceptors.request.use(requestInterceptor);
export { apiClient, requestInterceptor, setAuthToken };
